import React, { forwardRef } from "react";

import clsx from "clsx";

import { vars } from "~styles/themes/theme.css";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "../Box";

import type { BoxProps } from "../Box";
import type { ReactNode, Ref } from "react";

export interface PhoneMockupProps extends BoxProps {
  children?: ReactNode;
  className?: string;
}
export const PhoneMockup = forwardRef(
  (
    {
      children,
      maxWidth = "gridSpan3",
      className: userClassName,
      ...rest
    }: PhoneMockupProps,
    ref: Ref<HTMLElement>
  ) => {
    return (
      <Box
        ref={ref}
        width="100%"
        maxWidth={maxWidth}
        borderRadius="md"
        __border={`${vars.spacing.spacing0} solid ${vars.color.neutral.black}`}
        overflow="hidden"
        position="relative"
        className={clsx(userClassName, variantBackgroundColor.background_white)}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);
