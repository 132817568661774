import * as React from "react";

import clsx from "clsx";
import { animate } from "motion";

import { calc } from "@vanilla-extract/css-utils";

import { vars } from "~styles/themes/theme.css";

import { Box } from "~components/Box";

import { LogoListList } from "./_LogoListList";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type { SvgLogoProps } from "~components/SvgLogo";
import type { GetSprinklesArgs } from "~styles/getSprinkles.css";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface LogoListProps
  extends StoryblokBlok,
    Omit<BoxProps, "gridTemplateColumns"> {
  logoHeight: GetSprinklesArgs["height"];
  logos?: Array<SvgLogoProps["svgLogo"]>;
  isScrollable?: boolean;
}

const LOGO_GRID_GAP = vars.spacing.spacing10;
const WIDTH_MIN_CONTENT = "min-content";

const FLEX_PROPS: BoxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  __gap: LOGO_GRID_GAP,
  __rowGap: vars.spacing.spacing2,
  flexShrink: "1",
  width: WIDTH_MIN_CONTENT,
};

/** Renders a grid of logos, e.g. for vector logos or photography layouts. */
export function LogoList({
  logos,
  logoHeight = "spacing5",
  isScrollable,
  className: userClassName,
  ...rest
}: LogoListProps) {
  const refContainer = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (typeof window === "undefined") return;

    if (isScrollable && refContainer.current && Array.isArray(logos)) {
      animate(
        refContainer.current,
        {
          transform: [
            "translateX(0)",
            `translateX(${calc.add("100%", LOGO_GRID_GAP)})`,
          ],
        },
        {
          easing: "linear",
          repeat: Infinity,
          duration: 30,
        }
      );
    }
  }, [isScrollable, logos]);

  return (
    <Box
      className={clsx(
        userClassName,
        styles.getLogoListStyles({ isScrollable })
      )}
      {...rest}
    >
      <Box
        ref={refContainer}
        {...FLEX_PROPS}
        width={isScrollable ? WIDTH_MIN_CONTENT : "auto"}
      >
        <LogoListList
          {...FLEX_PROPS}
          logoListIndex={1}
          logos={logos}
          logoHeight={logoHeight}
          flexWrap={isScrollable ? "nowrap" : "wrap"}
          width={isScrollable ? WIDTH_MIN_CONTENT : "auto"}
        />
        {isScrollable && (
          <LogoListList
            {...FLEX_PROPS}
            position="absolute"
            __left={calc.add("100%", vars.spacing.spacing10)}
            logoListIndex={2}
            logos={logos}
            logoHeight={logoHeight}
          />
        )}
        {isScrollable && (
          <LogoListList
            {...FLEX_PROPS}
            position="absolute"
            logoListIndex={3}
            __right={calc.add("100%", vars.spacing.spacing10)}
            logos={logos}
            logoHeight={logoHeight}
          />
        )}
      </Box>
    </Box>
  );
}
